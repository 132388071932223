@import url("https://rsms.me/inter/inter.css");
html {
    font-family: "Inter", sans-serif !important;
    font-weight: bold;
}
@supports (font-variation-settings: normal) {
    html {
        font-family: "Inter var", sans-serif !important;
    }
}

@media screen and (max-width: 480px) {
    .filterDiv {
        display: none !important;
    }

    .namedesc {
        width: 60vw !important;
    }
    .iconborder {
        width: 20vw !important;
        height: 20vw !important;
    }

    .iconimg {
        width: 20vw !important;
        height: 20vw !important;
    }
    html,
    body {
        width: auto !important;

        overflow-x: hidden !important;
    }
    .mobilemarginzero {
        margin-bottom: 10px !important;
    }
    /* main page */

    .topBlueBar {
        width: 90vw !important;
        height: 45vw !important;
    }
    .topBlueBarText {
        font-size: 10vw !important;
    }

    .weaponContainer {
        display: grid;
        grid-template-columns: 9rem 9rem !important;
        grid-gap: 3rem !important;
        grid-auto-flow: row;
        margin-top: 20px;
    }
}

body {
    margin: 0px;
}

.statboxtext {
    color: rgba(0, 0, 0, 0.7);
}

.statbox {
    filter: drop-shadow(0 0.1rem 0.35rem rgba(191, 214, 238, 0.5));
}

.titlePerkText {
    font-family: "Inter";
    font-size: 40px;
    color: #ff5a52;
    padding-bottom: 30px;
}

h1 {
    font-family: "Inter" !important;
    color: white;
    /* font-weight: bold; */
}
h3 {
    font-family: "Inter" !important;
    color: white;
}
p {
    font-family: "Inter" !important;
    color: white;
}
h2 {
    font-family: "Inter" !important;
    color: white;
}
.weaponsPage {
    background-color: #180f34;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.weaponContainer {
    display: grid;
    grid-template-columns: 10rem 10rem 10rem 10rem;
    grid-gap: 5rem;
    grid-auto-flow: row;
    margin-top: 20px;
}

.individualWeapon {
    background-color: #e6f0fd;
    border-radius: 15px;
    /* width: 90% */
}
.individualWeapon:hover {
    cursor: pointer;
}
.backbutton:hover {
    cursor: pointer;
}
.backbutton {
    /* position: absolute; */

    position: fixed;
    top: 10;
    width: 120px;
    height: 120px;
    z-index: 100;
}

/* 
.weaponContainer {
    background-color: #180f34;
    justify-content: "center";
    align-items: "center";
    flex-direction: "column";
} */

/* Popover styling */

a {
    text-decoration: none;
}
