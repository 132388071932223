@media screen and (max-width: 480px) {
    html,
    body {
        width: auto !important;
        overflow-x: hidden !important;
    }

    .perksContainer {
        margin-left: 5vw !important;
        display: flex !important;
        flex-direction: column !important;
    }

    .perksImage {
        width: 35vw !important;
        height: 40vw !important;
        margin-bottom: 6vw !important;
        align-self: center !important;
    }

    .perkNameDesc {
        align-self: flex-start !important;
        margin-left: 0vw !important;
    }
    .perkNameText {
        padding-bottom: 5vw !important;
        font-size: 9vw !important;
    }

    .perkDescText {
        font-size: 4vw !important;
        padding-bottom: 3vw !important;
    }

    .abilitypassive {
        margin-left: 2vw !important;
        width: 80vw !important;
    }

    .abilityText {
        font-size: 6vw !important;
    }
    .passiveContainer {
        margin-left: 10vw !important;
        margin-right: 10vw !important;
        /* align-items: flex-start !important; */
    }

    .abilityActualName {
        text-align: center !important;
        font-size: 4.5vw !important;
    }
    .iconspecial {
        top: 2vw !important;
        left: 2.3vw !important;
        width: 15vw !important;
        height: 15vw !important;
    }

    .blueseparator {
        margin-bottom: 0vw !important;
    }
    #tester {
        padding-left: 3vw !important;
        padding-top: 8vw !important;
    }

    .namedesc {
        width: 55vw !important;
    }

    .ateveryrank {
        margin-left: 0vw !important;
    }
    .firstrow {
        margin-top: 5vw !important;
    }
    /* .backbutton {
        position: sticky;
        top: 50;
        width: 120vw;
    } */

    /* .statbox {
        width: 25vw !important;
        height: 15vw !important;
    }
    .statboxtext {
        font-size: 4vw !important;
    } */
}
