@import url("https://rsms.me/inter/inter.css");
html {
    font-family: "Inter", sans-serif !important;
    font-weight: bold;
}
@supports (font-variation-settings: normal) {
    html {
        font-family: "Inter var", sans-serif !important;
    }
}

@media screen and (max-width: 480px) {
    .filterDiv {
        display: none !important;
    }

    .namedesc {
        width: 60vw !important;
    }
    .iconborder {
        width: 20vw !important;
        height: 20vw !important;
    }

    .iconimg {
        width: 20vw !important;
        height: 20vw !important;
    }
    /* html,
    body {
        width: auto !important;
        overflow-x: hidden !important;
    } */
    .mobilemarginzero {
        margin-bottom: 10px !important;
    }
}

.titlePerkText {
    font-family: "Inter";
    font-size: 40px;
    color: #ff5a52;
    padding-bottom: 30px;
}

h1 {
    font-family: "Inter" !important;
    color: white;
    /* font-weight: bold; */
}
h3 {
    font-family: "Inter" !important;
    color: white;
}
p {
    font-family: "Inter" !important;
    color: white;
}
h2 {
    font-family: "Inter" !important;
    color: rgba(0, 0, 0, 0.7);
}

.filterDiv {
    display: flex;
    flex-direction: column;
    border: 3px solid #ff5a52;
    border-radius: 5px;
    height: 650px;
    width: 400px;
    margin-top: 50px;
    position: sticky;
    top: 30px;
    padding-left: 50px;
    padding-top: 0px;
    padding-right: 50px;
}

.category {
    padding-bottom: 30px;
}

.statbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 20px;
    background-color: rgb(255, 250, 250);
    justify-content: center;
    margin-left: 15px;
    filter: drop-shadow(0 0.1rem 0.35rem rgba(0, 0, 0, 0.3));
}

.statboxtext {
    font-size: 16px;
    margin-right: 15px;
    margin-left: -5px;
}
/* Popover styling */

a {
    text-decoration: none;
}

.popover__title {
    /* font-size: 24px;
    line-height: 36px;
    text-decoration: none;
    color: rgb(228, 68, 68);
    text-align: center;
    padding: 15px 0; */
}

.popover__wrapper {
    position: relative;
    /* margin-top: 1.5rem; */
    /* display: inline-block; */
}
.popover__content {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    /* left: -150px; */
    transform: translate(0, -200px);
    background-color: rgb(255, 226, 226);
    padding: 1rem;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    width: auto;
    margin-top: 40px;
}
.popover__content:before {
    position: absolute;
    z-index: -1;
    content: "";
    right: calc(50% - 10px);
    top: 130px;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent rgb(255, 226, 226) transparent;
    transition-duration: 0.1s;
    transition-property: transform;
    transform: rotate(180deg);
}
.popover__wrapper:hover .popover__content {
    z-index: 10;
    opacity: 1;
    visibility: visible;
    transform: translate(0, -250px);
    transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
}
.popover__message {
    text-align: center;
    font-weight: 700;
    font-size: 20px;
    color: rgb(74, 74, 74);
}
